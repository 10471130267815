<template>
    <div class="not-found-page">
        <div class="not-found-content">
            <img src="@/assets/altered_404.svg" class="altered-404" alt="404">
            <h1>You're looking for something that isn't there.</h1>
        </div>
    </div>                        
</template>

<script>
 
export default {
  name: 'NotFound',
  components: {
    
  }
}
</script>

<style scoped>
.not-found-page{
    background-color: black;
    height: 100vh;
    display: block;
    justify-content: center;
    align-items: center;
}

h1{
    font-size: 5vw;
    font-weight:900;
    color:#fdfd00;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media screen and (min-width: 800px) {
    h1{
        font-size: 5vw;
    }

    img{
        width: 60vw;
    }
}

@media screen and (min-width: 700px) and (max-width:799px) {
    h1{
        font-size: 7vw;
    }
}

@media screen and (max-width: 699px) {
    h1{
        font-size: 10vw;
    }
}

</style>