<template>
    <div class="restless-wrapper">
        <!-- As of now I didn't find a real way to use the environment var when loading the poster of the video background. Consider using something else than video background for background picture -->
        <video-background 
            :src="(``)"
            poster= "https://api.altereduniverse.ch/uploads/029_CL_20210123_0007_46cb04383b.jpg"
            style="height: 100vh;" 
            id="rest"
        >
            <div class="welcome-statement" style="" v-on:scroll="this.onScroll">
                <transition name="fade">
                    <div v-if="!scrolled">
                        <h1>JUST LET ME</h1>
                        <h1 id="rest">REST</h1>
                    </div>
                    <h1 v-else class="scrolled">
                        RESTLESS
                    </h1>
                </transition>
                <!--<h1 v-on:scroll.passive="this.scrolled=true" :class="{ scrolled: this.scrolled}">REST</h1>-->
            </div>
        </video-background>
        <div class="restless-content" :ref="'content'">
            <div id="text-container">
                <div >
                    <p v-html="restless.text1" class="restless-text1 restless-textbody"></p>
                </div>
                <div>
                    <p v-html="restless.text2" class="restless-text2 restless-textbody"></p>
                </div>
                <div>
                    <p v-html="restless.text3" class="restless-text2 restless-textbody"></p>
                </div>
            </div>
            <div
                id="picture-container" 
                v-viewer="{
                    movable: false,
                    navbar: false,
                    title: false,
                    movable: false, 
                    zoomable: false,
                    view: this.onView,
                    transition: false,
                    toolbar: {
                        zoomIn: 0,
                        zoomOut: 0,
                        oneToOne: 0,
                        reset: 0,
                        prev: 1,
                        play: {
                        show: 0,
                        size: 'large',
                        },
                        next: 1,
                        rotateLeft: 0,
                        rotateRight: 0,
                        flipHorizontal: 0,
                        flipVertical: 0,
                    }
                }">
                <b-card-group class="galleries gallery1" columns>
                    <div class="img-container" >
                        <b-card v-for="picture in restless.gallery1" :key="picture.id" >
                            <b-card-img-lazy :src="api + picture.formats.large.url" alt="Image" class="rounded-0 gallery-img" offset="0" ></b-card-img-lazy>
                        </b-card>
                    </div>
                </b-card-group>
                <b-card-group class="galleries gallery2" style="" columns>
                    <div class="img-container">
                        <b-card v-for="picture in restless.gallery2" :key="picture.id" >
                            <b-card-img-lazy :src="api + picture.formats.large.url" alt="Image" class="rounded-0 gallery-img" offset="0" ></b-card-img-lazy>
                        </b-card>
                    </div>
                </b-card-group>
                <b-card-group class="galleries gallery5" style="" columns>
                    <div class="img-container">
                        <b-card v-for="picture in restless.gallery5" :key="picture.id" >
                            <b-card-img-lazy :src="api + picture.formats.large.url" alt="Image" class="rounded-0 gallery-img" offset="0" ></b-card-img-lazy>
                        </b-card>
                    </div>
                </b-card-group>
                <b-card-group class="galleries gallery3" style="" columns>
                    <div class="img-container">
                        <b-card v-for="picture in restless.gallery3" :key="picture.id" >
                            <b-card-img-lazy :src="api + picture.formats.large.url" alt="Image" class="rounded-0 gallery-img" offset="0" ></b-card-img-lazy>
                        </b-card>
                    </div>
                </b-card-group>
                <b-card-group class="galleries gallery6" style="" columns>
                    <div class="img-container">
                        <b-card v-for="picture in restless.gallery6" :key="picture.id" >
                            <b-card-img-lazy :src="api + picture.formats.large.url" alt="Image" class="rounded-0 gallery-img" offset="0" ></b-card-img-lazy>
                        </b-card>
                    </div>
                </b-card-group>
                <b-card-group class="galleries gallery4" style="" columns>
                    <div class="img-container">
                        <b-card v-for="picture in restless.gallery4" :key="picture.id" >
                            <b-card-img-lazy :src="api + picture.formats.large.url" alt="Image" class="rounded-0 gallery-img" offset="0" ></b-card-img-lazy>
                        </b-card>
                    </div>
                </b-card-group>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Restless',
        data () {
            return {
                restless: [],
                id: this.$route.params.id,
                error: null,
                headers: {'Content-Type': 'application/json'},
                scrolled: false,
                api: process.env.VUE_APP_ALTERED_ROOT_API,
            }
        },
        methods: {
            parseJSON: function (resp) {
            return (resp.json ? resp.json() : resp);
            },
            checkStatus: function (resp) {
                if (resp.status >= 200 && resp.status < 300) {
                    return resp;
                }
                return this.parseJSON(resp).then((resp) => {
                    throw resp;
                });
            },
            onScroll: function () {
                this.scrolled=true;
                const ref = this.$refs.content;
                if (ref) {
                  //  ref.scrollIntoView({ behavior: "smooth" });
                }
            },
            onView: function (){
                var element = document.getElementById('picture-container');
                element.scrollTop = element.scrollHeight;
            }
        },
        async mounted () {
            window.scrollTo(0, 0)

            //var rest = document.getElementById('rest');
            window.addEventListener('scroll', this.onScroll);

            try {
            const response = await fetch(this.api + "/projects/" + this.id, {
                method: 'GET',
                headers: this.headers,
            }).then(this.checkStatus)
                .then(this.parseJSON);
            this.restless = response
            } catch (error) {
              this.error = error
            }
        }
    }

</script>

<style scoped>
.page-view .post-preview{
    min-height: 100vh;
    padding-top:25vh;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.scrolled{
    top: 0;
    position: fixed;
    color: #fdec00!important;
}

.restless-textbody{
    margin:2vw auto 0 auto;
    font-size:1em;
}

.restless-text1.restless-textbody{
    margin: 10vw auto 0 auto;
}

.gallery-img{
    margin: 0.5em 0 0.5em 0;
    transition: opacity 3s ease-in-out;
}
.post-title{
    font-weight: 800;
}

.galleries .card{
    border: none;
}

.card-body{
    padding: 0;
}

.welcome-statement h1{
    font-size: 7vw;
    font-weight:900;
    color:#fdfd00;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: left;
    padding-left: 3vw;
}

.img-container{
    cursor: pointer;
}

@media screen and (min-width: 900px) {
 /*   .restless-textbody{
        width:55vw;
    }
    .container{
        max-width:95vw;
    }
    .galleries .img-container{
        max-width: 1140px;
        margin: 0 auto 0 auto;
    }*/
    #text-container{
        max-width: 45vw;
        max-height: 100vh;
        overflow: scroll;
        padding: 0 3vw;
        float: left;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

     /* Hide scrollbar for Chrome, Safari and Opera */
    #text-container::-webkit-scrollbar #picture-container::-webkit-scrollbar {
        display: none;
    }

    #picture-container{
        max-width: 55vw;
        padding: 1.5vw 3vw;
        margin-left: auto;
        overflow: scroll;
        max-height: 100vh;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .restless-content{
        overflow: scroll;
    }

    .gallery2 .gallery-img{
        width: 49vw;
    }

    .gallery2{
        margin: 4em 0;
    }

    .gallery6{
        margin: 3em 0;
    }

    .gallery6 .gallery-img{
        width: 23.8vw;
    }

    .galleries.gallery6.card-columns{
        column-count:2;
    }

    .galleries.gallery2.card-columns{
        column-count:1;
    }
}

@media screen and (min-width: 700px) and (max-width:799px) {

}

@media screen and (max-width: 899px) {
    
    #text-container{
        margin: 10vh auto 0 auto;
        font-size: 1em;
    }

    #text-container{
        padding: 0 15px 0 15px;
    }
}  
</style>